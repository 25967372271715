.mark {
  position: fixed;
  z-index: -1;
  bottom: 0;
}

.mark > img:first-child {
  width: 300px;
  position: fixed;
  bottom: 0;
  right: 0;
}

.mark > img:last-child {
  width: 300px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.box img {
  width: 70px;
  height: 40px;
  fill: #fff;
  margin-top: 1rem;
}

.box p, span {
  text-align: center;
  color: var(--blue-text-color)
}

.box p:first-child {
  margin-top: -50px;
}

.option {
  margin-bottom: 20px;
  margin-top: 0px;
}

.divCancel {
  font-size: 3rem;
  text-align: center;
  padding: 0px 20px;
}