.containerInput {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 10px 0px;
  width: 90vw;
}

label {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.containerInput > input {
  width: 100%;
  height: 40px;
  border: 2px solid var(--border-color);
  border-radius: 5px;
  margin-top: 5px;
  color: var(--border-color);
  text-align: center;
  font-size: 20px;
  font-style: italic;
  line-height: 37px;
  background-color: rgba(242, 242, 242, 0.5);
  outline: none;
}

.withICon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.withICon > button{
  background-color: transparent;
  border: none;
  padding: 0;
}

@media (min-width: 500px) {
  .containerInput {
    width: 350px;
  }
}