@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

.DialogCardAlert p:first-child {
  font-size: 3.2rem;
  font-weight: bold;
}

.DialogCardAlert p {
  padding: 0px 10px;
  font-size: 3rem;
}

.DialogCVV {
  background-color: rgba(235, 235, 235, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.DialogCVV .text {
  color: #000;
  font-style: italic;
  font-size: 2rem;
}

.DialogCVV img {
  width: 100px;
  height: 70px;
}

.container {
  display: flex;
}

.container>img {
  align-self: center;
  width: 13.5rem;
  height: 14.1rem;
  margin: 50px 0px 10px 0px;
}

.container>p {
  font-size: 3.5rem;
  text-align: center;
  padding: 7px;
}

.container>ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container>ul>li * {
  font-size: 2.4rem;
  text-align: center;
  font-weight: bold;
  line-height: 3.4rem;
}

.buttonContinueCard {
  padding: 10px;
  align-self: center;
  width: 23.4rem;
  background-color: var(--green-button-color);
  border: none;
  color: #003940;
  font-size: 3rem;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s;
  margin: 20px 0px 0px 0px;
}

.logoSSL {
  width: 9rem !important;
  height: 9rem !important;
  align-self: center;
}

.containerSmall {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  align-self: center;
}

.containerSmall>.containerInput {
  width: 45%;
}

.dialogCardError .MuiPaper-root {
  margin: 0px 20px;
}

.dialogCardError p span {
  font-size: 2rem ;
  padding: 0px 20px;
}

.dialogCardError p {
  font-size: 2.5rem;
  padding: 0px 20px;
}

.dialogCardError p:first-child {
  font-size: 3rem;
  font-weight: bold;

}

.dialogCardError button {
  width: 31.3rem;
  height: 8.2rem;
  background-color: var(--green-button-color);
  color: #003940;
  font-size: 2.5rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}

.simulatorOtherValue {
  background-color: transparent;
  border: 1px solid var(--blue-text-color);
  padding: 10px 0px;
  margin-top: 50px;
  margin-bottom: -30px;
  width: 200px;
  align-self: center;
  border-radius: 5px;
  text-transform: lowercase;
}

.buttonConcluirEmprestimo {
  text-transform: uppercase;
}

.simulatorOtherValue::first-letter {
  text-transform: uppercase;
}

.titleAlert {
  font-size: 3.5rem !important;
  font-weight: bold;
}

.dialogAlert p {
  padding: 0px 10px;
  font-size: 2rem;
}

.listAlert {
  padding-inline-start: 20px;
}

.listAlert * {
  color: rgb(255, 0, 0);
  font-size: 2rem;
  list-style: none;

}

.dialogAlert button {
  width: 31.3rem;
  min-height: 8.2rem;
  font-size: 2.5rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}

.dialogAvoidBlocking p {
  padding: 0px 10px;
  font-size: 2rem;
}
.dialogAvoidBlocking .cardAvoid p{
  text-align: center;
  font-size: 2rem;
  margin-top: 0;
}

.dialogAvoidBlocking .cardAvoid p:first-child u {
  color: red !important;
  font-size: 2.3rem;
}

.titleBlockingConfirm {
  font-size: 2.1rem !important;
  font-weight: bold;
}

.buttonAvoidBlockingConfirm {
  width: 31.3rem !important;
  height: auto !important;
  font-size: 1.6rem !important;
  padding: 5px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}


.infoCardAccountPayment {
  margin: 1rem;
  width: 90vw;
  align-self: center;
  border: 2px solid var(--border-color);
  border-radius: 5px;
  padding: 0px 1rem;
  background-color: rgba(217, 217, 217, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.infoCardAccountPayment .buttonContinueCard {
  margin: 3rem !important;
  text-transform: uppercase;
}

.infoCardAccountPayment > p, .infoCardAccountPayment > p span{
  font-style: italic;
  font-weight: bold;
  color: var(--blue-text-color);
  text-align: center;
  padding: 5px;
  font-size: 2.5rem;
  font-family: 'Montserrat';
}

.infoCardAccountPayment > p > strong > u {
  font-size: 3rem;
}

.infoCardAccountPayment > p span {
  padding: 0;
}

.buttonAvoidBlockingNotConfirm {
  width: 31.3rem !important;
  height: auto !important;
  font-size: 1.6rem !important;
  padding: 5px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  background-color: rgba(245, 226, 12, 0.737) !important;
}

.buttonAvoidBlockingNotConfirm, .buttonAvoidBlockingNotConfirm u, .buttonAvoidBlockingNotConfirm span {
  color: rgba(131, 129, 129, 0.872) !important;
}

.buttonAvoidBlockingNotConfirm > u {
  line-height: 1.2;
}

.confirmButton {
  background-color: var(--blue-text-color) !important;
  color: white !important;
}

.firstInsufficientLimit p {
  padding: 0px 10px;
  font-size: 2.5rem;
}

.firstInsufficientLimit p:first-child {
  font-size: 3.5rem;
}

.emphasis,
.emphasis * {
  font-size: 3rem !important;
  color: red;
}

.firstInsufficientLimit button {
  width: 30rem;
  height: auto;
  padding: 15px;
}

.dialogCardErrorF p {
  font-size: 2rem;
  padding: 0px 20px;
}

.dialogCardErrorF p:first-child {
  font-size: 3rem;
}

.errorCardForLimit p {
  font-size: 2rem;
  padding: 0px 20px;
}

.errorCardForLimit p:first-child {
  font-size: 3rem;
}

.dialogTimer {
  width: 100%;
  height: auto;
}

.dialogTimer p {
  font-size: 3rem;
  padding: 20px;
  text-align: center;
}

.dialogTimer p>span {
  color: var(--blue-text-color) !important;
  font-size: 3rem;
  font-weight: bold;
}

.dialogTimer p:first-child>span {
  font-size: 5rem;
}

.containerValuesMinMax {
  display: flex;
  max-width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.containerValuesMinMax .or {
  padding: 0;
}

.containerValuesMinMax .buttonValuesMinMax>p,
span {}

.containerValuesMinMax .buttonValuesMinMax {
  margin: 10px 10px;
  width: 30rem;
  background-color: var(--blue-text-color);
}

.containerSameValue {
  width: 100% !important;
  height: auto !important;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.containerSameValue p+span {
  font-size: 30px;
  text-align: center;
}

.spanColorAndSize {
  font-size: 16.6px !important;
  color: #868686 !important;
}

.spanModal {
  font-size: 30px !important;
  color: var(--blue-text-color) !important;
}

.spanError {
  font-size: 20.2px !important;
  color: #000 !important;
}

.smallPlaceHolder::placeholder {
  font-size: 1.8rem;
}

.buttonChallenge {
  padding: 10px;
  height: auto !important;
  min-width: 30rem !important;
  font-size: 2.5rem !important;
}



@media (min-width: 500px) {
  .containerSmall {
    width: 350px;
  }
}