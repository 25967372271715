
.loading .MuiDialog-paper {
  background-color: transparent;
  box-shadow: none;
  overflow: hidden;
}

.MuiCircularProgress-circle {
  color: #fff;
}
