
.containerComputerAccess h1 {
  font-size: 5rem;
  margin-top: 50px;
  z-index: 10;

}

.containerComputerAccess h2 {
  font-size: 4rem;
  font-weight: normal;
  text-align: center;
  z-index: 10;

}

.containerComputerAccess h3 {
  font-size: 2rem;
  text-align: center;
  font-weight: normal;
  z-index: 10;

}

.containerComputerAccess {
  margin: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
}

.containerComputerAccess .logoComputerAccess {
  width: 400px;
  height: auto;
  z-index: 10;


}

.qrCode {
  width: 250px;
  height: 250px;
  margin-top: 50px;
  z-index: 10;
}

.hand {
  position: absolute;
  width: 700px;
  height: 500px;
  bottom: 0;
  left: 0vw;
  z-index: 5;
}

@media (max-height: 1100px) {
  .hand {
    width: 600px;
    height: 400px;
  }
}

@media (max-width: 1425px) {
  .hand {
    width: 500px;
    height: 300px;
  }
}

@media (min-width: 1700px) {
  .hand {
    left: 10vw;
  }
}
@media (min-width: 2000px) {
  .hand {
    left: 15vw;
  }
}

@media (min-width: 2300px) {
  .hand {
    left: 20vw;
    /* background-color: red; */
  }
}

@media (min-width: 3000px) {
  .hand {
    left: 25vw;
  }
}