.containerInput, .containerInputDialog {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 10px 0px;
  width: 90vw;
}


.defaultLabel {
  font-size: 2rem;
  font-weight: bold;
  color: #000;
}

.genderLabel {
  font-size: 1.7rem;
  font-weight: bold;
  color: #000;
}

.containerInput select,
input {
  width: 100%;
  height: 40px;
  border: 2px solid var(--border-color);
  border-radius: 5px;
  margin-top: 5px;
  color: var(--border-color);
  text-align: center;
  font-size: 20px;
  font-style: italic;
  line-height: 37px;
  background-color: rgba(242, 242, 242, 0.5);
  outline: none;
}

.containerInputDialog select,
input {
  width: 'auto';
  height: 40px;
  border: 2px solid var(--border-color);
  border-radius: 5px;
  margin-top: 5px;
  color: var(--border-color);
  text-align: center;
  font-size: 20px;
  font-style: italic;
  line-height: 37px;
  background-color: rgba(242, 242, 242, 0.5);
  outline: none;
}

.containerInput select, .containerInputDialog select {
  text-align: left;
  padding-left: 5px;
}

@media (min-width: 500px) {
  .containerInput, .containerInputDialog {
    width: 350px;
  }
}