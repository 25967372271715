.containerMaintenance {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px 0px;
  flex-wrap: wrap;
}

.containerMaintenance p {
  font-size: 2rem;
  text-align: center;
  padding: 0px 10px;
}

.containerMaintenance p:first-child {
  font-size: 3rem;
}

.gearImages {
  display: flex;
  align-items: flex-end;
}

.gearImages img {
  width: 100px;
  height: 100px;
  animation: rotateRight 4s linear infinite;
}

.gearImages img:last-child {
  width: 50px;
  height: 50px;
  animation: rotateLeft 4s linear infinite;
}

@keyframes rotateRight {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateLeft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}