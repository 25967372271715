.progressIndicator {
  display: flex;
  width: 100%;
  height: 6.1rem;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.progressIndicator > p {
  font-size: 2rem;
  font-weight: bold;
}

.progressIndicator > button > img {
  width: 4rem;
  height: 4rem;
}

.progressIndicator > button {
  background-color: transparent;
  border: none;
}

.indicationBar {
  display: flex;
  height: 8px;
  background-color: #C4C4C4;
  width: 100%;
}

.indicationBar::before {
  content: "";
  width: calc(var(--progress) * 0.85%);
  background-color: var(--blue-text-color);
  transition: all 0.5s ease-in-out;
  
}