.tarja {
  align-self: center;
  width: 100vw;
  height: auto;
  background-color: #120a8f;
  margin-top: auto;
}

.tarja p {
  color: #fff;
  text-align: center;
  padding: 1rem;
  font-size: 0.7rem !important;
}
