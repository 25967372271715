.divOperationError {
  height: 8.3rem;
  width: 100%;
  background-color: #00AFEF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divOperationError > p {
  color: white;
  font-size: 3.8rem;
  font-weight: bold;
}