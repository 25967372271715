#containerPhotos p,
.ulPhotos li {
    font-size: 2.7rem ;
}

#containerPhotos> .buttonInitPhotos {
    width: auto;
    height: auto;
    align-self: center;
    background-color: var(--blue-text-color);
    color: white;
    font-size: 3rem;
    padding: 10px 15px;
    font-weight: bold;
    border-radius: 5px;
    margin: 3rem 5rem;
}

#containerPhotos > .input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}
#containerPhotos .labelInput {
    width: 250px;
    height: auto;
    align-self: center;
    text-align: center;
    background-color: var(--blue-text-color);
    color: white;
    font-size: 2.5rem;
    padding: 10px 15px;
    font-weight: bold;
    border-radius: 5px;
}

.imgTutorial {
    width: 100% !important;
    height: auto !important;
}

.previewImage {
    width: 90%;
    height: auto;
    align-self: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.previewImage > img {
    width: 90%;
    height: auto;
}

.previewImage > p {
    margin-top: 7rem;
}

.previewImage > p:last-of-type {
    margin-top: 1rem;
    font-size: 1.5rem !important;
}

.buttonNextPhoto {
    width: auto;
    height: auto;
    align-self: center;
    background-color: var(--green-button-color);
    color: black;
    font-size: 3rem;
    padding: 10px 15px;
    font-weight: bold;
    border-radius: 5px;
    margin: 3rem 5rem;
    border: 1px solid gray;
    scroll-margin: 20px;


}

.buttonNextPhotoDisabled {
    width: auto;
    height: auto;
    align-self: center;
    background-color: var(--border-color);
    color: rgba(255, 255, 255, 0.512);
    font-size: 3rem;
    padding: 10px 15px;
    font-weight: bold;
    border-radius: 5px;
    margin: 3rem 5rem;
    border: none;
    scroll-margin: 20px;
}

.viewPhotos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 90%;
    align-self: center;
    
}

.viewPhotos > img{
    width: 90%;
    margin-bottom: 10rem;
}

.viewPhotos > img:last-child{
    margin-bottom: 4rem;
}

.containerPhotos {
    border: 1px solid gray;
}

.viewPhotos > strong{
    text-transform: lowercase;
    font-size: 3rem;
    margin-bottom: 5px;
}

.viewPhotos > strong::first-letter{
    text-transform: uppercase;
}

@media (min-width: 550px) {
    .viewPhotos > img{
        width: 60rem;
        margin: 0px 5rem;
    }
    .previewImage > img {
        width: 60rem;
        margin: 0px 5rem;
    }
}