@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');


* span {
  font-family: 'Poppins';
}

* p {
  font-family: 'Poppins';
}

* li {
  font-family: 'Poppins';
}

* button {
  font-family: 'Poppins';
}

.banner {
  width: 100%;
  max-width: 500px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}


.master {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.MuiDialog-paper {
  width: 316px;
  /* height: 273px; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.MuiDialog-paper>p {
  font-size: 30px;
  text-align: center;
}

.MuiDialog-paper>button {
  width: 149px;
  height: 44px;
  background-color: var(--green-button-color);
  border: none;
  color: #003940;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s;
  margin-bottom: 15px;
}

.MuiDialog-paper>button:focus {
  background-color: #B3E03C;
  color: #002c31;
}

.divWellcome {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 100%;
  overflow-x: hidden;
  flex: 1;
}

.containerImages {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  flex: 2;
}

.womanImage {
  width: 378px;
  height: 335px;
  top: -80px;
  left: -78px;
  position: absolute;
  z-index: 2;
}

.circleOne {
  position: absolute;
  left: 0;
  top: -25px;
  mix-blend-mode: normal;
  transform: rotate(10.32deg);
  z-index: 3;
}

.circleTwo {
  position: absolute;
  left: 0;
  top: -25px;
  mix-blend-mode: normal;
  transform: rotate(-2.32deg);
  z-index: 3;
}

.vectorBlue {
  position: absolute;
  width: 196.5px;
  height: 121.5px;
  right: -20px;
  top: 70px;
  z-index: 0;
}

.vectorGreen {
  position: absolute;
  width: 372px;
  height: 280px;
  right: 0px;
  top: -82px;
  z-index: 1;
}

.containerWellcome {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
  /* background-color: red; */
  margin-top: 7rem;
}

.containerWellcome>img {
  width: 280px;
  height: auto !important;
  margin-top: -30px;
}

.containerWellcome>div>p {
  font-weight: bold;
  font-size: 34px;
  margin: 0;
  text-align: center;
}

.containerWellcome>div {
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.containerDescription {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  flex: 2;
}

.containerDescription>p {
  font-size: 4rem;
  text-align: center;
}

.containerButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 7rem;
}

.containerButtons>a {
  text-decoration: underline #002C31;
  color: #003940;
  font-size: 2rem;
  margin-top: 2rem;
  z-index: 10;

}

.targetWhatsApp {
  text-decoration: none !important;
}

.targetWhatsApp>button>p {
  color: #003940;
}

.requestButton {
  width: 31.3rem;
  height: 8.2rem;
  background-color: var(--green-button-color);
  border: none;
  color: black;
  font-size: 3rem;
  font-weight: bold;
  border-radius: 0.5rem;
  animation: pulse linear 1.5s infinite;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;

}

.requestButton:focus {
  background-color: #B3E03C;
  color: #002c31;
}

.aboutButton {
  width: 14.9rem;
  height: 4.4rem;
  color: #003940;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 2rem;
  transition: all 0.3s;
}

.aboutButton:focus {
  background-color: #B3E03C;
  color: #002c31;
  border: none;

}

.defaultClassForTarja {
  display: flex;
  flex-direction: column;
  height: 100%;
}


.divHaveCard>p {
  font-size: 3.5rem;
  text-align: center;
  padding: 0rem 1rem;
}

.questionButton {
  width: 23.4rem;
  height: 6.2rem;
  background-color: var(--green-button-color);
  border: none;
  color: #003940;
  font-size: 3rem;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s;
  margin: 1rem 0rem;
}

.questionButton:focus {
  background-color: #B3E03C;
  color: #002c31;
}

.ContainerDontHaveCard>* p {
  color: black;
}

.ContainerDontHaveCard>* li {
  color: black;
  font-size: 2.5rem;
  text-align: center;
  padding: 2rem 0rem;
}

.ContainerDontHaveCard>* li>strong {
  color: black;
}

.ContainerDontHaveCard>.title>p {
  font-size: 3.5rem;
  text-align: center;
  font-weight: bold;
  padding: 0rem 1rem;
}

.ContainerDontHaveCard span {
  font-size: 2.5rem;
  text-align: center;
}

.ContainerDontHaveCard>.description>p {
  font-size: 2.5rem;
  text-align: center;
  padding: 0rem 1rem;
}

.ContainerDontHaveCard>.description>p>strong {
  color: #000;
}

.description>p>strong>span {
  font-family: 'Poppins';
  color: #000;
}

.ContainerDontHaveCard>.containerButtons {
  margin-bottom: 3px;
}

.sendWhatsAppButton {
  width: 31.3rem;
  height: 9.2rem;
  background-color: var(--green-button-color);
  border: none;
  color: #003940;
  font-size: 3rem;
  font-weight: bold;
  border-radius: 0.5rem;
  transition: all 0.3s;
  margin: 1rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 2rem;
  text-align: start;
}

.sendWhatsAppButton:focus {
  background-color: #B3E03C;
  color: #002c31;
}

.sendWhatsAppButton>img {
  width: 5.5rem;
  height: 5.5rem;
  margin-right: 1rem;
}

.divHaveDocument {
  height: 100%;
  font-size: 3.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.divHaveDocument .cpfRequired {
  padding: 0rem 1rem;
}

.outlined {
  text-decoration: underline;
}

.allertDocument {
  align-self: center;
  width: 31.2rem;
  height: 14.5rem;
  background-color: rgba(217, 217, 217, 0.5);
  border-radius: 0.5rem;
  border: 0.2rem solid var(--border-color);
  text-align: center;
  color: #868686;
  font-size: 2rem;
  font-style: italic;
  margin-bottom: 2rem;
  font-weight: 300;
  line-height: 2.4rem;
}

.exitButton {
  width: 18.4rem;
  height: 6.6rem;
  background-color: #003940;
  border: none;
  color: #ffffff;
  font-size: 3rem;
  font-weight: bold;
  line-height: 3.7rem;
  border-radius: 0.5rem;
  margin-top: 2rem;
  transition: all 0.3s;
}

.exitButtonDocument {
  width: 14.9rem;
  height: 4.4rem;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.75);
  border: 0.2rem solid #003940;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 1rem;
}

.exitButton:focus {
  background-color: #002C31;
}

.divCardLimit {
  height: 100%;
  font-size: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.divCardLimit>p {
  padding: 1rem;
}

.textCard {
  align-self: center;
  width: 28.4rem;
}

.textCard>p {
  color: #868686;
  font-size: 2rem;
  font-style: italic;
}

.valueCardLimit {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.valueCardLimit>input {
  font-family: sans-serif;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  width: 31.6rem;
  height: 6.2rem;
  border-radius: 0.5rem;
  background-color: rgba(242, 242, 242, 0.5);
  color: var(--border-color);
  border: 0.2px solid var(--border-color);
  margin-bottom: 3rem;
}

.valueCardLimit>input:focus {
  background-color: rgba(190, 245, 74, 0.16);
  color: #000;
  border-color: #BBE54B;
}

.valueCardLimit>div {
  position: absolute;
  top: 5.5rem;
  content: '';
  width: 25rem;
  height: 1px;
  border-bottom: 2.5px dashed var(--border-color);
}

.buttonCardActive {
  width: 24.3rem;
  height: 6.2rem;
  background-color: #003940;
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  border-radius: 5px;
  align-self: center;
}

.buttonCardDisabled {
  width: 24.3rem;
  height: 6.2rem;
  background-color: #c3c3c3;
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  border-radius: 5px;
  align-self: center;
}

.divCongratulations>p>strong {
  font-size: 4rem;
  margin: 2rem 0px 5rem 0px;
}


.divCongratulations img {
  width: 15rem;
  height: 13rem;
  margin-top: 10rem;
}

.cardPreApproved {
  width: 31.2rem;
  height: 8.8rem;
  background-color: rgba(190, 245, 74, 0.16);
  border: 2px solid #BBE54B;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardAprovedDisabled {
  background-color: #acaaaa;
  border: none;

}

.cardAprovedDisabled p {
  color: rgba(92, 90, 90, 0.75) !important;
}



.noTop {
  margin-top: 5rem;
}

.toTop {
  margin-bottom: 4rem;
}

.defaultMarginBottom {
  margin-bottom: 8rem !important;
}

.cardPreApproved>p {
  font-weight: bold;
  font-family: Arial;
  text-align: center;
}

.containerButtonsProposal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.containerButtonsProposal>button {
  width: 31.3rem;
  height: 8.2rem;
  background-color: var(--green-button-color);
  color: #003940;
  font-size: 2.5rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}

.containerButtonsProposal button:first-child {
  animation: pulse 1.5s infinite;
}

.containerButtonsProposal>button:last-child {
  background-color: rgb(201, 198, 198);
  font-size: 2rem;
  color: #fff;
  margin-top: 1.5rem;
  animation: pulselow 3s infinite;
}

.otherValue {
  height: 100%;
  width: 30.8rem;
  align-self: center;
}

.otherValue>p {
  color: #868686;
  font-size: 2rem;
  text-align: center;
}

.otherValue>p>span {
  font-family: Arial;
  color: #868686;
  font-size: 2rem;
}

.dialogGeneric>span {
  font-family: Arial;
  font-weight: bold;
  font-size: 4rem !important;
}

.dialogGeneric {
  font-size: 4rem !important;
}

.divParcel {
  font-size: 3.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.divParcel p {
  padding: 0px 5px;
}

.divParcel>p>span {
  font-family: Arial;
}

.listParcels {
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.parcel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40rem;
  min-height: 7.5rem;
  padding: 10px 5px;
  border-radius: 5px;
  background-color: #F2F2F2;
  margin: 10px 0px;
  flex: 1;
  transition: all 0.15s ease-in;
}

.parcelDisabled {
  opacity: 0.5;
}

.parcelDisabled div {
  margin-left: 3rem;
}

.parcelPulse {
  animation: pulse 1.5s infinite;
}

.parcel * {
  font-family: 'Poppins', sans-serif;
}

.parcel>input {
  width: 2.5rem;
  height: 2.5rem;
  flex: 1.5;
  border-color: #c3c3c3;
  accent-color: #000 !important;
}

.parcel>div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
}

.buttonParcelContinue {
  background-color: var(--green-button-color);
  width: auto;
  height: auto;
  font-size: 3rem;
  padding: 10px 15px;
  font-weight: bold;
  color: var(--blue-text-color);
  border-radius: 5px;
  border: none;
  margin: 10px 0px;
  align-self: center;
  word-wrap: break-word;
}

.parcel .divValues {
  flex: 7;
}

.parcel .divCets {
  flex: 1.9;
  height: auto;
  border-left: 0.1px solid #5f5f5f2b !important;
  padding-left: 5px;
}

.parcel .divCets>p,
.parcel .divCets>p>strong {
  color: var(--border-color) !important;
  font-size: 1rem !important;
  padding: 0px;
  margin: 0px;
  align-self: center;
}

.parcel .divCets hr {
  width: 100%;
  height: 1px;
  border: none;
  background-color:  #5f5f5f2b;
  margin: 2px 0px;
}


.parcel>div>p:first-child {
  font-size: 2.4rem;
  color: #003940;
  margin: 0px;
}

.parcel>div>p:last-child {
  font-size: 1.5rem;
  color: var(--border-color);
  margin: 0px;
}

.info {
  width: 32.9rem;
  height: auto;
  background-color: rgba(217, 217, 217, 0.5);
  border: 2px solid rgba(95, 95, 95, 1);
  text-align: center;
  margin: 20px 0px;
}

.info * {
  color: rgba(134, 134, 134, 1);
}

.info>p {
  font-size: 2rem;
  font-style: italic;
}

.info>p>span {
  font-family: 'Poppins';
}

.logoSSLVerify {
  width: 9rem;
  height: 9rem;
  align-self: center;
  margin-bottom: 2rem;
}

.containerButtons .MuiLink-root {
  text-align: center;
  color: grey;
  font-size: 1.2rem;
}

.buttonSimulatorOther {
  background-color: var(--blue-text-color) !important;
  color: #fff !important;
}

.alertButtonContinue {
  animation: pulse linear 1.5s infinite;
}

.sintegraError p {
  font-size: 2rem;
  text-align: center;
  padding: 5px 10px;
}

.ContainerPlayer {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.player {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 200px;
}

.ContainerPlayer p {
  font-size: 3rem;
}

.ContainerPlayer p,
span,
strong {
  text-align: center;
}

.ContainerPlayer span {
  color: rgba(92, 90, 90, 0.75);
  font-size: 2.5rem;
}

.ContainerPlayer button {
  width: 90%;
  padding: 5px;
  text-align: center;
  color: #fff;
  margin: 5px 0px;
  border-radius: 10px;
  border: none;
  padding: 10px;
  font-weight: bold;
}

.ContainerPlayer button:first-of-type {
  margin-top: 10px;
  background-color: var(--green-button-color);
  height: auto;
  color: var(--blue-text-color);
}

.ContainerPlayer button:last-of-type {
  background-color: var(--blue-text-color);
  height: 5rem;
}

.parcelNumbers {
  font-size: "27px" !important;
  color: '#003940' !important;
}

.imageAntiFraudes {
  max-width: 90vw;
  align-self: center;
  margin-top: 2rem;
}

.buttonDontInterest {
  background-color: rgb(201, 198, 198) !important;
  font-size: 1.2rem;
}

.buttonPlayerContinue {
  font-size: 1.2rem;
}

.informTimePayment,
.informTimePayment span {
  color: var(--border-color);
  font-size: 2rem;
}

@media (max-height: 650px) {

  .womanImage {
    width: 300px;
    height: 250px;
    top: -60px;
    left: -50px;

  }

  .circleOne {
    width: 250px;

  }

  .circleTwo {
    width: 200px;
  }

  .vectorBlue {

    width: 100px;
    height: 100px;
    right: -20px;
    top: 50px;

  }

  .vectorGreen {

    width: 300px;
    height: 200px;
    right: -60px;
    top: -82px;
  }

  .containerWellcome>img {
    width: 200px;
    height: 40px;
    margin-top: -30px;
  }

  .containerWellcome>div>p {
    font-weight: bold;
    font-size: 25px;
    margin: 0;
    text-align: center;
  }

  .containerWellcome>div {
    display: flex;
    height: 150px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .containerDescription {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    flex: 2;
  }

  .containerDescription>p {
    font-size: 20px;
    text-align: center;
  }

  .containerButtons>a {
    font-size: 15px;
  }

  .requestButton {
    width: 250px;
    height: 60px;
    font-size: 15px;

  }

}

@media (min-height: 650px) and (max-height: 762px) {
  .womanImage {
    width: 350px;
    height: 300px;
    top: -80px;
    left: -50px;

  }

  .circleOne {
    width: 350px;

  }

  .circleTwo {
    width: 250px;
  }

  .vectorBlue {

    width: 100px;
    height: 200px;
    right: 0px;
    top: 50px;

  }

  .vectorGreen {

    width: 300px;
    height: 300px;
    right: -60px;
    top: -82px;
  }

  .containerWellcome>img {
    width: 230px;
    height: 40px;
    margin-top: -30px;
  }

  .containerWellcome>div>p {
    font-weight: bold;
    font-size: 30px;
    margin: 0;
    text-align: center;
  }

  .containerDescription {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    flex: 2;
  }

  .containerDescription>p {
    font-size: 25px;
    text-align: center;
  }

  .containerButtons>a {
    font-size: 17px;
  }

  .requestButton {
    width: 250px;
    height: 55px !important;
    font-size: 20px;

  }

}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);

  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulselow {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.02);

  }

  100% {
    transform: scale(1);
  }
}