* span {
  font-family: "Poppins";
}

.DialogRegister p:first-child {
  font-size: 2.5rem;
}

.DialogRegister li {
  font-size: 2rem;
}

.container {
  max-height: 1000vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-width: 100%;
  flex: 1;
}

.container .title {
  font-size: 3.5rem;
  text-align: center;
}

.info {
  width: 31.6rem;
  align-self: center;
  border: 2px solid var(--border-color);
  border-radius: 5px;
  padding: 0px 1rem;
}

.info > p, .info > p > span {
  font-size: 2rem;
  font-style: italic;
  color: #868686 !important;
  text-align: center;
}

.containerTitle, .containerTitleAccount {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 3rem;
}

.containerTitle>strong {
  font-size: 3rem;
  text-align: left;
}

.containerTitleAccount>strong, .containerTitleAccount>strong>span {
  font-size: 2rem;
  text-align: center;
}

.bar {
  width: 85vw;
  background-color: #003940;
  border: 2.5px solid #003940;
}

.buttonContinue {
  padding: 1rem;
  align-self: center;
  width: 23.4rem;
  height: 6.2rem;
  background-color: var(--green-button-color);
  border: none;
  color: #003940;
  font-size: 3rem;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s;
  margin: 2rem 0px 3rem 0px;
}

.infoCard {
  margin: 1.5rem;
  width: 31.6rem;
  align-self: center;
  border: 2px solid var(--border-color);
  border-radius: 5px;
  padding: 0px 1rem;
  background-color: rgba(217, 217, 217, 0.5);
}

.infoCard > p {
  font-style: italic;
  font-weight: bold;
  color: #868686;
  text-align: center;
  padding: 5px;
  font-size: 2rem;
}

.InfoPix {
  margin: 1.5rem;
  width: 31.6rem;
  align-self: center;
  border: 2px solid var(--border-color);
  border-radius: 5px;
  padding: 0px 10px;
  background-color: rgba(217, 217, 217, 0.5);
}

.InfoPix > p {
  font-style: italic;
  color: #868686;
  text-align: center;
  padding: 5px;
}

.InfoPix > p > strong {
  color: #868686;
}

.InfoPix > p > strong:last-child  {
  font-family: 'Poppins';
}

.recaptcha {
  align-self: center;
  /* margin: 20px 0px; */
}

.MuiDialog-paper .buttonSintegra {
  width: auto;
  height: auto;
  padding: 10px;
}

.textRecaptcha {
  align-self: center;
  font-size: 1.3rem !important;
  text-align: left !important;
  color: rgb(16, 2, 2) !important;
}

.DialogAccount {
  width: 100%;
}

.DialogAccount p{
  font-size: 2rem;
  padding: 5px 10px;
}

.DialogAccount p:first-child{
  font-size: 3rem;
}


.DialogAccount ul {
  font-size: 2rem;
  margin: 0;
  list-style: inside;
  padding: 0;
}

.awaitSMS {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.awaitSMS p {
  font-size: 3rem;
  text-align: center;
  padding: 0px 1rem;
}

.awaitSMS img {
  width: 40rem;
  height: 40rem;
  margin-right: -30px;
}

.contentSMS {
  width: 100vw;
}

@media (max-width: 280px) {
  .info, .infoCard {
    width: 90vw;
  }
}

@media (max-width:330px) {
  .contentSMS {
    width: 110%;
  }
}