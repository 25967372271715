span * {
  font-family: 'Poppins';
}

.containerSMS {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
}

.containerSMS>img {
  width: 25rem;
  height: 20rem;
}

.containerSMS>p {
  text-align: center;
  padding: 0px 10px;
  font-size: 3rem;
}

.containerSMS i {
  text-decoration: underline;
}

.containerSuccessFull {
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.containerSuccessFull>img {
  margin: 5rem;
  height: 30rem;
  width: 20rem;
}

.congratulations {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.congratulations img {
  width: 15rem;
  height: 13rem;

}

.congratulations>p {
  font-size: 3.5rem;
  text-align: center;
  line-height: 4.8rem;
}

.congratulations>p:last-child {
  margin-top: 0;
}

.alerts {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 100;
  margin-top: calc(0.1 * 100vw);
}

.content>p {
  font-size: 2.5rem;
  text-align: center;
  padding: 0px 20px;
}

.containerShare {
  position: relative;
  width: 90vw;
  height: 9.2rem;
  border-radius: 5px;
  background-color: #fff;
  border: 2px solid #003940;
  display: flex;
  align-self: center;
  align-items: center;
  flex: 1;
}

.containerShare>img {
  width: 4rem;
  height: 4rem;
  flex: 2;
}

.containerShare>p {
  flex: 8;
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
}

.containerBackImages {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.containerImageOne,
.containerImageTwo {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: calc(0.015 * 100vw);
}

.containerImageOne {
  z-index: 2;
  background-color: #bef54a;
  clip-path: polygon(
    0% 5%,
    1.5% 2.5%,
    2% 2%,
    5% 0.5%,
    7% 0.2%,
    9% 0.09%,
    12% 0.3%,
    97% 9%,
    98.5% 9.5%,
    100% 10%,
    100% 100%,
    0% 100%
    )
}

.containerImageTwo {
  background-color: #00afef;
  clip-path: polygon(93% 0.3%,
      96% 0.5%,
      98% 1%,
      100% 2%,
      100% 100%,
      0% 100%,
      0% 10%);
}

.containerBackImages>img {
  position: absolute;
  width: 100%;
}

.clickSignImage {
  width: 30rem !important;
  height: 7rem !important;
  margin: 25px 0px 15px 0px;
}

.WhatsAppImage{
  width: 27rem !important;
  height: 8rem !important;
  margin: 5px 0px 20px 0px;

}

@media (max-width: 427px){
  .containerBackImages {
    height: 110%;
    
  }
}
@media (max-width: 386px){
  .containerBackImages {
    height: 120%;
  }
}
@media (max-width: 318px){
  .containerBackImages {
    height: 130%;
  }
  .containerShare>p {
    font-size: 17px;
  }
}
@media (max-width: 280px){
  .containerBackImages {
    height: 150%;
  }
  .containerShare>p {
    font-size: 16px;
  }
}
@media (max-width: 262px){
  .containerBackImages {
    height: 170%;
  }
  .containerShare>p {
    font-size: 15px;
  }
}

@media (max-height: 741px){
  .containerBackImages {
    height: 130%;
  }
}

@media (max-height: 570px){
  .containerBackImages {
    height: 160%;
  }
}

@media (max-height: 462px){
  .containerBackImages {
    height: 190%;
  }
}

@media (min-width: 500px) {
  .containerShare {
    width: 313px;
  }
}

@media (max-width: 360px) {
  .containerShare {
    margin-bottom: 20px;
  }
}