@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400&display=swap');

html {
  font-size: 40.5%;
}

@media (max-height: 650px) and (max-height: 762px) {
  html {
    font-size: 42.5%;
  }
}

@media (max-width: 370px) {
  html {
    font-size: 42.5%;
  }
}

* {
  font-family: 'Montserrat', sans-serif;
  color: var(--blue-text-color);
}

.snack * {
  color: #fff !important;
  font-size: 1.5rem;
}

.SnackbarContent-root .SnackbarItem-message svg {
  fill: #fff !important;
}

:root {
  --blue-text-color: #003940;

  --green-button-color: #BEF54A;

  --border-color: #5f5f5f;


}