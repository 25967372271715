@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

.container {
  display: flex;
}

.containerFaceID {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.container>img {
  align-self: center;
  width: 13.5rem;
  height: 14.1rem;
  margin: 50px 0px 10px 0px;
}

.container>p {
  font-size: 3.5rem;
  text-align: center;
  padding: 7px;
}

.container>ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container>ul>li * {
  font-size: 2.4rem;
  text-align: center;
  font-weight: bold;
  line-height: 3.4rem;
}

.buttonContinue {
  padding: 10px;
  align-self: center;
  width: 23.4rem;
  height: 6.2rem;
  background-color: var(--green-button-color);
  border: none;
  color: #003940;
  font-size: 3rem;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s;
  margin: 20px 0px 30px 0px;
}

.idwall {
  display: flex;
  align-self: center;
  justify-self: center;
  width: 100vw;
  height: 100vh !important;
}

.waiting {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
}

.containerWaiting {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.waiting > p {
  font-size: 3rem;
  text-align: center;
}

.waiting img {
  width: 12rem;
  height: 10rem;
  margin: 30px 0px 70px 0px;
  animation: rotate 2s linear infinite;
}

.sugestion {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sugestion ul {
  margin: 0px;
  padding: 0px;
}

.sugestion li {
  text-align: center;
  list-style-type: none;
  padding: 20px;
  color: #000;
}

.sugestion a {
  text-decoration: none;
  word-break: break-word;
}

.dialogPhotos button{
  width: 90%;
  height: auto;
  padding: 10px;
}

.dialogPhotos button:first-of-type{
  background-color: var(--green-button-color);
  color: rgb(37, 36, 36);
}

.dialogPhotos button:last-of-type{
  background-color: orange;
  color: rgb(37, 36, 36);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}