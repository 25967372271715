.infoCardAccount {
    margin: 1.5rem;
    width: auto;
    align-self: center;
    border: 2px solid var(--border-color);
    border-radius: 5px;
    padding: 0px 1rem;
    background-color: rgba(217, 217, 217, 0.5);
}

.infoCardAccount > p, .infoCardAccount > p span{
    font-style: italic;
    font-weight: bold;
    color: #868686;
    text-align: center;
    padding: 5px;
    font-size: 2rem;
    font-family: 'Montserrat';
}
.infoCardAccount > p span {
    padding: 0;
}

.ContainerTextTransfeera p {
    font-size: 1.5rem;
    text-align: center;
    padding: 0px 10px;
    color: var(--border-color);
}

.ContainerTransfeera p, .ContainerTransfeera li{
    font-size: 2rem;
}